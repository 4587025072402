<template>
    <div class="d-flex align-items-center" style="min-height: 100vh;">
        <div class="col-sm-8 col-md-6 col-lg-4 mx-auto" style="min-width: 300px;">
            <div class="text-center mt-5 mb-1">
                <img src="@/assets/images/1.png" alt="Luma" width="200" class="mb-3">
            </div>
            <div class="card navbar-shadow">
                <div class="card-header text-center">
                    <div class="media">
                        <div class="media-body">
                            <h4 class="card-title">Recuperação de Senha</h4>
                            <p class="card-subtitle text-50">Insira seu e-mail</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <button type="button" class="btn mb-16pt btn-light btn-block"><span -->
                    <!-- class="fab fa-google mr-2"></span> -->
                    <!-- Continue with Google -->
                    <!-- </button> -->
                    <!-- <div class="page-separator justify-content-center"> -->
                    <!-- <div class="page-separator__text bg-white">or</div> -->
                    <!-- </div> -->
                    <div novalidate="novalidate" class="">
                        <div role="group" class="form-group" id="__BVID__594"><label for="email"
                                class="d-block form-label" id="__BVID__594__BV_label_">E-mail:</label>
                            <div class="bv-no-focus-ring">
                                <div role="group" class="input-group input-group-merge"><input v-model="email"
                                        id="email" type="email" placeholder="Seu e-mail" required="required"
                                        aria-required="true" class="form-control form-control-prepended">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text"><span class="fas fa-envelope"></span></div>
                                    </div>
                                </div>
                                <div class="invalid-feedback"></div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <b-spinner v-if="load"></b-spinner>
                        </div>
                        <div v-if="!load" class="form-group">
                            <button @click=recoveryPassword() class="btn btn-primary btn-block">Enviar</button>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="text-center text-black-50">
                        <router-link to="/login">
                            Voltar
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {
//   Page,
//   PageHeader,
//   PageSeparator
// } from 'vue-luma'

import Page from "@/components/Page.vue";
import http from "@/http";

export default {
    layout: 'blank',
    extends: Page,
    data() {
        return {
            email: '',
            title: 'Solicitar nova Senha',
            load: false,
        }
    },
    methods: {
        recoveryPassword() {
            if(!this.email){
                this.$notifyError('Insira um e-mail pare recuperar sua senha!');
                return;
            }
            this.load = true;

            http.post('mentors/recoveryPassword', {'email': this.email})
                .then(response => {
                    this.load = false;
                    this.$notifySuccess(response.data);
                })
                .catch(e => {
                    console.error('Erro ao enviar e-mail: ', e.response);
                    this.$notifyError(e);
                })
                .finally(() => {
                    this.load = false;
                })

        }
    }
}
</script>
